import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AppRoutesObject } from "../../../pages/app-routes.component";
import {
  selectLeadCountsWithLimits,
  selectTopStripData,
} from "../../../store/api/api.selector";
import { useDispatch } from "react-redux";
import { setTopUpOrgId } from "../../../store/global/global.reducer";
import {
  useGetAllLeadsCountQuery,
  useGetOrgsQuery,
  useGetSummariesQuery,
  useUsersSyncQuery,
} from "../../../store/api/api";
import WarningYellow from "../../../assets/images/warning-yellow.svg";
import WarningRed from "../../../assets/images/warning-red.svg";

export const TopStrip = () => {
  const dispatch = useDispatch();
  const data = useSelector(selectTopStripData);
  const { bgColor, org, code, strong, text } = data;
  const [prefix, suffix] = strong ? text.split(strong) : ["", ""];
  const navigate = useNavigate();
  const onClick = () => {
    if (code === "CRM Trial") {
      window.open(
        "https://superfone.odoo.com/book/sf-setup-trialcrm",
        "_blank"
      );
      return;
    } else if ((code || "").toLowerCase().includes("minutes") && org?.id) {
      dispatch(setTopUpOrgId(org.id));
    }
    navigate(AppRoutesObject.Teams.url);
  };
  const { isSuccess: isOrgsSuccess } = useGetOrgsQuery();
  const { isSuccess: isSummariesSuccess } = useGetSummariesQuery();
  const location = useLocation();
  const { data: usersSync } = useUsersSyncQuery();
  const { data: summaries } = useGetSummariesQuery();
  const { data: orgLeadCounts } = useGetAllLeadsCountQuery(undefined, {
    selectFromResult: ({ data }) =>
      selectLeadCountsWithLimits({
        leadCounts: data || [],
        summaries: summaries || [],
      }),
  });

  const full = orgLeadCounts?.find((o) => o.full && !!o.limit);
  const almostFull = orgLeadCounts?.find((o) => o.almost_full && !!o.limit);

  if (!usersSync?.current_subscription?.subscription) {
    return null;
  }
  if (location.pathname === AppRoutesObject.Teams.url) {
    return null;
  }

  if (isOrgsSuccess && isSummariesSuccess) {
    if (prefix || suffix || strong) {
      return (
        <div
          style={{
            lineHeight: "2rem",
            cursor: "pointer",
            textAlign: "center",
          }}
          onClick={onClick}
        >
          <div
            style={{
              background: bgColor,
              color: "blue",
              textDecoration: "underline",
            }}
          >
            {prefix ? <span>{prefix}</span> : null}
            <strong>{strong}</strong>
            {suffix ? <span>{suffix}</span> : null}
          </div>
        </div>
      );
    } else if (full) {
      return (
        <Link to={AppRoutesObject.Tasks.url}>
          <div className="cursor-pointer border-b border-[#dc2f2f] bg-[#fde9e9] py-2 px-4 flex gap-6 items-center justify-between">
            <div className="flex gap-2 items-center">
              <img src={WarningRed} alt="" className="w-6 h-6" />
              <div className="text-[14px] uppercase leading-5 text-[#b62020]">
                Your lead storage is full
              </div>
              <div className="text-[#61626b] text-[14px] leading-5">
                You are missing new leads 😟
              </div>
            </div>
            <button className="rounded-lg py-2 bg-white px-3 border border-secondary uppercase text-[14px] leading-5 font-semibold text-secondary">
              view
            </button>
          </div>
        </Link>
      );
    } else if (almostFull) {
      return (
        <Link to={AppRoutesObject.Tasks.url}>
          <div className="cursor-pointer border-b border-[#f4ca64] bg-[#fdf3d7] py-2 px-4 flex gap-6 items-center justify-between">
            <div className="flex gap-2 items-center">
              <img src={WarningYellow} alt="" className="w-6 h-6" />
              <div className="text-[14px] uppercase leading-5 text-[#5c4813]">
                Your lead storage is almost full
              </div>
              <div className="text-[#61626b] text-[14px] leading-5">
                No new leads will be added once full
              </div>
            </div>
            <button className="rounded-lg py-2 bg-white px-3 border border-secondary uppercase text-[14px] leading-5 font-semibold text-secondary">
              view
            </button>
          </div>
        </Link>
      );
    }
  }
  return null;
};
