import { GetCustomerNotesResponse } from "../store/api/types/get-customer-notes.types";
import { formatShortDateTime } from "./utils";

export const transformToNotesRows = (
  notes: GetCustomerNotesResponse["rows"]
) => {
  return (
    notes?.map((n) => ({
      id: n.id,
      note: n.note,
      createdBy: `${n.created_by_user.first_name || ""} ${
        n.created_by_user.last_name || ""
      }`,
      createdAt: formatShortDateTime(n.createdAt),
    })) || []
  );
};

export type NotesRow = ReturnType<typeof transformToNotesRows>[number];
