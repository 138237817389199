export const countryCodeObject = {
  IN: "+91",
};

export type CountryShortName = keyof typeof countryCodeObject;

export const removeCountryCode = (params: {
  phoneNumber?: string | null;
  country?: CountryShortName;
  numberMasking?: boolean;
}) => {
  const { country, phoneNumber, numberMasking } = params || {};
  let phoneNumberString =
    typeof phoneNumber === "string"
      ? phoneNumber.replaceAll(countryCodeObject[country || "IN"], "")
      : "";
  if (numberMasking) {
    phoneNumberString =
      phoneNumberString.slice(0, 2) + "XXXXXXXX" + phoneNumberString.slice(-2);
  }
  return phoneNumberString;
};

export const getNumberWithCountryCode = (params: {
  number?: string;
  country?: CountryShortName;
  numberMasking?: boolean;
}) => {
  const { country, number, numberMasking } = params || {};
  let phoneNumber =
    number?.replace(countryCodeObject[country || "IN"], "") || "";
  if (numberMasking && phoneNumber) {
    phoneNumber = phoneNumber.slice(0, 2) + "XXXXXXXX" + phoneNumber.slice(-2);
  }
  return [countryCodeObject[country || "IN"] || "", phoneNumber || ""];
};
