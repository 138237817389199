import { useNavigate } from "react-router-dom";
import { ErrorScreen } from "../../components/error-screen/error-screen.component";
import { AppRoutesObject } from "../app-routes.component";
import { useEffectOnce } from "../../hooks/use-effect-once";
import { scrollToTop } from "../../utils/scroll-to-top";
import {
  EVENT_NAME,
  PAGE_NAME,
  PAGE_SIZE,
  clevertapPush,
} from "../../store/clevertap/clevertap";

const NotFound = () => {
  const navigate = useNavigate();
  const backHome = () => {
    navigate(AppRoutesObject.Home.url);
  };
  useEffectOnce(() => {
    scrollToTop();
    clevertapPush({
      event_name: EVENT_NAME.PAGE_VIEW,
      event_data: {
        PageName: PAGE_NAME.NOT_FOUND,
        PageSize: PAGE_SIZE.FULL_PAGE,
      },
    });
  });
  return (
    <ErrorScreen
      errorMessage="Sorry, the page you visited does not exist."
      errorTitle="404"
      onAction={backHome}
    />
  );
};

export default NotFound;
