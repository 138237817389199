import { PropsWithChildren } from "react";
import {
  useGetMemberQuery,
  useGetSummariesQuery,
  useGetWabaTokensQuery,
  useLoginOrgQuery,
  useUsersSyncQuery,
} from "../../store/api/api";
import { AppRoutesObject } from "../../pages/app-routes.component";
import { useLocation } from "react-router-dom";
import { AppLayout } from "../app-layout/app-layout.component";

export const RoleBasedRoute = (props: PropsWithChildren) => {
  const { data: usersSync } = useUsersSyncQuery();
  const { data: user } = useGetMemberQuery(usersSync?.user?.id || 0, {
    skip:
      !usersSync?.user?.id ||
      !usersSync?.org?.id ||
      !usersSync?.current_subscription?.subscription,
  });

  const { data: loginOrgResponse } = useLoginOrgQuery(usersSync?.org?.id || 0, {
    skip: !usersSync?.org?.id,
  });

  const { data: wabaTokens } = useGetWabaTokensQuery(undefined, {
    skip: !usersSync?.org?.id || !loginOrgResponse?.userData?.id,
  });

  const { data: summaries } = useGetSummariesQuery();
  const userOrgIds = user?.orgs?.map((o) => o.id) || [];
  const userSummaries =
    summaries?.filter?.((s) => userOrgIds.includes(s.org_id)) || [];
  const isWhatsappActive = (wabaTokens || [])
    .filter((token) => userOrgIds.includes(token.org_id))
    .some((w) => !!w?.token);
  const isCRMActive = userSummaries.some((s) => {
    return s.is_paid_crm_active || s.is_trial_crm_active;
  });
  const location = useLocation();
  const route = location?.pathname?.split("/")?.[1] || "";

  const isLoggedInUserStaff =
    user?.orgs?.every((o) => o?.role_slug === "member") || false;
  if (usersSync?.user?.role_slug) {
    const { access, reason } = getAccessPerRole(
      `/${route}`,
      isLoggedInUserStaff,
      {
        crm: isCRMActive,
        whatsapp: isWhatsappActive,
      }
    );
    if (access) {
      return <>{props.children}</>;
    } else {
      return (
        <AppLayout>
          <div className="flex justify-center items-center grow">
            <span className="text-lg">{reason}</span>
          </div>
        </AppLayout>
      );
    }
  }

  return <>{props.children}</>;
};

export const getAccessPerRole = (
  route: string,
  isStaff: boolean,
  features: { crm: boolean; whatsapp: boolean }
) => {
  switch (route) {
    default:
      return { access: true, reason: "" };
    case AppRoutesObject.Integrations.url:
    case AppRoutesObject.Automations.url:
    case AppRoutesObject.Tasks.url:
      return {
        access: features.crm,
        reason: "CRM is not active in any of yours Orgs",
      };
    case AppRoutesObject.Settings.url:
      if (isStaff) {
        return {
          access: false,
          reason: "Staff does not have access to Settings",
        };
      }
      return {
        access: true,
        reason: "",
      };
  }
};
