import { api, useLogoutMutation } from "../../store/api/api";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { deleteCookie } from "../../utils/utils";
import { Button, ButtonProps, Tooltip } from "antd";

export const LogoutButton = (props: ButtonProps) => {
  const { children, ...rest } = props;
  const [logout, { isLoading }] = useLogoutMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onLogout = async () => {
    try {
      await logout();
      dispatch(api.util.resetApiState());
      navigate("/login");
      deleteCookie("connect.sid");
    } catch (e) {}
  };

  return (
    <Tooltip title="Logout">
      <Button
        onClick={onLogout}
        loading={isLoading}
        disabled={isLoading}
        {...rest}
      >
        {children || null}
      </Button>
    </Tooltip>
  );
};
