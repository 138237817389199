import { Modal } from "antd";
import { useUsersSyncQuery } from "../../store/api/api";
import { Suspense, lazy, useState } from "react";

const LiveCallsList = lazy(() => import("./live-calls-list.component"));
const ClickToCallDisabledModal = lazy(
  () => import("../click-to-call/click-to-call-disabled-modal.component")
);

export const LiveCall = () => {
  const { data: usersSync } = useUsersSyncQuery();
  const enabled = usersSync?.settings?.click_to_call || false;
  const [clickToCallDisabledModal, setClickToCallDisabledModal] =
    useState(false);
  const [liveCallsList, setLiveCallsList] = useState(false);

  return (
    <button
      className={`relative`}
      onClick={() => {
        if (enabled) {
          if (liveCallsList === false) {
            setLiveCallsList(true);
          }
        } else {
          if (clickToCallDisabledModal === false) {
            setClickToCallDisabledModal(true);
          }
        }
      }}
    >
      <span className="absolute flex h-3 w-3 -right-1 -top-1">
        <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-[#abf873]/80 opacity-75"></span>
        <span className="relative inline-flex rounded-full h-3 w-3 bg-green-400"></span>
      </span>
      <div
        className={`border-secondary px-4 py-1 border rounded-[4px]`}
        style={{
          boxShadow: "1px 2px 4px black",
        }}
      >
        <span className={`text-primary font-semibold`}>
          {"Live Call Dashboard"}
        </span>
      </div>
      <Modal
        open={clickToCallDisabledModal}
        onCancel={() => {
          setClickToCallDisabledModal(false);
        }}
        footer={null}
        centered
      >
        <Suspense>
          <ClickToCallDisabledModal />
        </Suspense>
      </Modal>
      <Modal
        open={liveCallsList}
        onCancel={() => {
          setLiveCallsList(false);
        }}
        footer={null}
        closeIcon={null}
        title={"Live Calls"}
        width={"800px"}
        destroyOnClose
      >
        {liveCallsList ? (
          <Suspense>
            <LiveCallsList />
          </Suspense>
        ) : null}
      </Modal>
    </button>
  );
};
