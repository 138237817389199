import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type VALID_LANGUAGES = "english" | "hindi" | "CUSTOM";

export const english = "english";
export const hindi = "hindi";
export const male = "male";
export const female = "female";
export const other = "other";
export type GENDER = "male" | "female" | "CUSTOM";

export const custom = "custom";
export const defaultCT = "default";

export type GlobalState = Readonly<{
  topUpOrgId: number;
  purchaseOrgId: number;
  renewOrgId: number;
  upgradeOrgId: number;
  globalOrgId: number;
  globalOrgIds: number[];
  callInitiated: boolean;
  siderCollapsed: boolean;
  openKeys: string[];
  selectedOrgId: number;
  editMode: boolean;
  selectedStaff: number;
  step: number;
  language: VALID_LANGUAGES;
  gender: GENDER;
  message: string;
  toggleEnableSave: boolean;
  copyCallerTuneModal: boolean;
}>;

export const initialState: GlobalState = {
  topUpOrgId: 0,
  purchaseOrgId: 0,
  renewOrgId: 0,
  upgradeOrgId: 0,
  globalOrgId: 0,
  globalOrgIds: [],
  callInitiated: false,
  siderCollapsed: false,
  openKeys: [],
  selectedOrgId: 0,
  editMode: false,
  selectedStaff: 0,
  step: 1,
  language: "english",
  gender: "female",
  message: "",
  toggleEnableSave: false,
  copyCallerTuneModal: false,
};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setTopUpOrgId: (state, action: PayloadAction<number>) => {
      state.topUpOrgId = action.payload;
    },
    setPurchaseOrgId: (state, action: PayloadAction<number>) => {
      state.purchaseOrgId = action.payload;
    },
    setRenewOrgId: (state, action: PayloadAction<number>) => {
      state.renewOrgId = action.payload;
    },
    setGlobalOrgId: (state, action: PayloadAction<number>) => {
      state.globalOrgId = action.payload;
    },
    setGlobalOrgIds: (state, action: PayloadAction<number[]>) => {
      state.globalOrgIds = action.payload;
    },
    setCallInitiated: (state, action: PayloadAction<boolean>) => {
      state.callInitiated = action.payload;
    },
    setSidebar: (state, action: PayloadAction<boolean>) => {
      state.siderCollapsed = action.payload;
    },
    setOpenKeys: (state, action: PayloadAction<string[]>) => {
      state.openKeys = action.payload;
    },
    setEditMode: (state, action: PayloadAction<boolean>) => {
      state.editMode = action.payload;
    },
    setSelectedOrg: (state, action: PayloadAction<number>) => {
      state.selectedOrgId = action.payload;
    },
    setSelectedStaff: (state, action: PayloadAction<number>) => {
      state.selectedStaff = action.payload;
    },
    setStep: (state, action: PayloadAction<number>) => {
      state.step = action.payload;
    },
    setLanguage: (state, action: PayloadAction<VALID_LANGUAGES>) => {
      state.language = action.payload;
    },
    setGender: (state, action: PayloadAction<GENDER>) => {
      state.gender = action.payload;
    },
    setMessage: (state, action: PayloadAction<string>) => {
      state.message = action.payload;
    },
    toggleEnableSave: (state, action: PayloadAction<boolean>) => {
      state.toggleEnableSave = action.payload;
    },
    toggleCopyCallerTuneModal: (state, action: PayloadAction<boolean>) => {
      state.copyCallerTuneModal = action.payload;
    },
    setUpgradeOrgId: (state, action: PayloadAction<number>) => {
      state.upgradeOrgId = action.payload;
    },
  },
});

export const {
  setTopUpOrgId,
  setPurchaseOrgId,
  setRenewOrgId,
  setUpgradeOrgId,
  setGlobalOrgId,
  setGlobalOrgIds,
  setCallInitiated,
  setOpenKeys,
  setSidebar,
  setEditMode,
  setSelectedOrg,
  setSelectedStaff,
  setStep,
  setLanguage,
  setGender,
  setMessage,
  toggleEnableSave,
  toggleCopyCallerTuneModal,
} = globalSlice.actions;

export const globalReducer = globalSlice.reducer;
