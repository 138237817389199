import Lite from "../../assets/images/lite.svg";
import Pro from "../../assets/images/pro.svg";

export const planConfig = {
  18: {
    amount: 400,
    short_name: "Phone",
    name: "Business Phone",
    icon: null,
    description: "Shareable phone number for your team",
    backgroundClass: "bg-[#FFF6CE]",
    variants: [
      {
        id: 1079,
        name: "Half Yearly",
        disabled: false,
        monthly: 343,
        text: {
          primary: "Free incoming & 3000 outgoing mins",
          secondary: "Top-up at ₹600/ per 1000 min.",
        },
      },
      {
        id: 1078,
        name: "Yearly",
        disabled: false,
        monthly: 400,
        text: {
          primary: "Free incoming & 6000 outgoing mins",
          secondary: "Top-up at ₹600/ per 1000 min.",
        },
      },
    ],
  },
  19: {
    amount: 499,
    short_name: "Lite",
    name: "All-in-one",
    icon: <img className="w-[60px] h-5" alt="lite" src={Lite} />,
    description: "Affordable combo for small businesses",
    backgroundClass: "bg-[#E2FDFA]",
    variants: [
      {
        id: 1088,
        name: "Half Yearly",
        disabled: true,
        monthly: 0,
        text: {
          primary: "",
          secondary: "",
        },
      },
      {
        id: 1080,
        name: "Yearly",
        disabled: false,
        monthly: 427,
        text: {
          primary: "Free incoming & 6000 outgoing mins",
          secondary: "Top-up at ₹600/ per 1000 min.",
        },
      },
    ],
  },
  20: {
    amount: 999,
    short_name: "Pro",
    name: "All-in-one",
    icon: <img className="w-[60px] h-5" alt="pro" src={Pro} />,
    description: "Advanced plan for growing businesses",
    backgroundClass: "bg-[#F3E6F9]",
    variants: [
      {
        id: 1083,
        name: "Half Yearly",
        disabled: false,
        monthly: 999,
        text: {
          primary: "Free incoming & 3000 outgoing mins",
          secondary: "Top-up at ₹600/ per 1000 min.",
        },
      },
      {
        id: 1082,
        name: "Yearly",
        disabled: false,
        monthly: 856,
        text: {
          primary: "Free incoming & 6000 outgoing mins",
          secondary: "Top-up at ₹600/ per 1000 min.",
        },
      },
    ],
  },
};

export enum plan_ids {
  biz = 18,
  lite = 19,
  pro = 20,
}

export const planIdArray = [18, 19, 20];

export const almost_full_limit = 0.8;

export const businessPlanFeatures = [
  "30 days Call Recording",
  "Business Caller Tune",
  "Reports",
];

export const basicCRMFeatures = [
  "1 lead source integration",
  "Unlimited leads, 500 storage limit",
  "Fresh lead alert, tasks, reminders",
  "Web dashboard (owners only)",
  "Data protection & number masking",
];

export const advancedCRMFeatures = [
  "Multiple lead source integration",
  "Unlimited leads, 10000 storage limit",
  "Agent Assignment",
  "Web dashboard (all users)",
  "Unlimited tags, lead stages, message templates",
  "Advanced Reports",
  "Data protection & number masking",
];

export const enterpriseFeatures = [
  "Manage multiple numbers",
  "Webhooks & Integrations",
  "Custom lead integrations",
  "Automations",
  "Initiate call from web dashboard",
  "AI Call summary coming soon",
];
