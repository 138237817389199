import { Modal } from "antd";
import styled from "styled-components";
import { colors } from "../../utils/constants";

export const StyledModal = styled(Modal)`
  /* .ant-modal-header {
    background: ${colors.primary};
    .ant-modal-title {
      color: white;
    }
  } */
  .ant-modal-header {
    margin-bottom: 0;
    padding: 8px 0px;
    text-align: center;
  }

  .ant-modal-content {
    padding: 0;
  }
  .ant-modal-close-x {
    color: white;
  }
  .ant-modal-body {
    padding: 0;
    height: 90vh;
  }

  .ant-modal-wrap {
    z-index: 1020;
  }
`;
