import { combineReducers } from "redux";
import { globalReducer } from "./global/global.reducer";
import { api } from "./api/api";
import { whatsappApi } from "./api/whatsapp.api";

export const rootReducer = combineReducers({
  global: globalReducer,
  [api.reducerPath]: api.reducer,
  [whatsappApi.reducerPath]: whatsappApi.reducer,
});
