import { Button, Spin } from "antd";
import ChevronRightWhite from "../../assets/images/chevron-right-white.svg";
import UserEngagement from "../../assets/images/user-engagement.svg";
import Pro from "../../assets/images/pro.svg";
import ChevronRightBlue from "../../assets/images/chevron-right-blue.svg";
import WarningRed from "../../assets/images/warning-red.svg";
import WarningYellow from "../../assets/images/warning-yellow.svg";
import Selection from "../../assets/images/selection.svg";
import CheckYellow from "../../assets/images/check-yellow.svg";
import CheckPink from "../../assets/images/check-pink.svg";
import CheckGreen from "../../assets/images/check-green.svg";
import Close from "../../assets/images/Close.svg";
import {
  useGetSummariesQuery,
  useLazyLoginOrgQuery,
} from "../../store/api/api";
import { useDispatch } from "react-redux";
import { setUpgradeOrgId } from "../../store/global/global.reducer";
import { Link } from "react-router-dom";
import { AppRoutesObject } from "../../pages/app-routes.component";
import { useMemo } from "react";
import { contactUs, encrypt } from "../../utils/utils";
import { plan_ids } from "../purchase/constants";
import { CustomerServiceOutlined } from "@ant-design/icons";

export const LeadFullNudge = (
  props: Readonly<{
    maxLeads: number;
    onClose: () => void;
    orgId: number;
    planId: number;
  }>
) => {
  const { maxLeads, onClose, orgId, planId } = props;
  const [loginOrg, { isFetching, isLoading }] = useLazyLoginOrgQuery();
  const dispatch = useDispatch();

  const encryptedOrgId = useMemo(() => {
    return encrypt(orgId);
  }, [orgId]);
  const isPro = planId === plan_ids["pro"];
  return (
    <div className="flex flex-col">
      <div className="h-[153px] bg-[#fde9e9] flex justify-center items-center">
        <img
          src={WarningRed}
          className="w-[115px] h-[115px]"
          alt="warning-red"
        />
      </div>
      <div className="flex flex-col">
        <div className="flex flex-col gap-2 px-6 py-4">
          <div className="text-[#b62020] text-base">
            {maxLeads
              ? `${maxLeads} of ${maxLeads} leads used`
              : `Lead limit reached`}
          </div>
          <div className="text-[21px] leading-7 text-[#263238]">
            Your lead storage is full 😟
          </div>
          <div className="py-2 text-base text-[#61626b]">
            You can choose to import contacts without adding any tasks.
          </div>
        </div>
        <div className="px-6 py-4">
          {isPro ? (
            <button
              className="w-full h-12 border border-primary bg-white text-primary uppercase text-[15px] leading-5 font-medium rounded-full"
              onClick={() => {
                onClose();
                contactUs(`I want more leads in Pro plan`);
              }}
            >
              Contact Us
            </button>
          ) : (
            <Spin spinning={isFetching || isLoading}>
              <button
                onClick={async () => {
                  onClose();
                  await loginOrg(orgId);
                  dispatch(setUpgradeOrgId(orgId));
                }}
                className="w-full cursor-pointer p-3 flex gap-3 items-center bg-[#f0eefc] border border-[#d2c3f7] rounded-lg"
              >
                <img src={UserEngagement} className="w-10 h-10" alt="" />
                <div className="grow flex flex-col gap-1">
                  <div className="whitespace-nowrap flex gap-1 items-center">
                    <span className="text-[rgba(97, 98, 107, 1)] text-[13px] leading-5">
                      Upgrade to
                    </span>
                    <span className="flex gap-2 items-center">
                      <div className="uppercase text-[#9857bd] text-[13px] leading-5 font-semibold">
                        All-in-one{" "}
                      </div>
                      <img src={Pro} alt="pro" className="w-[52px] h-[22px]" />
                    </span>
                  </div>
                  <div className="text-base text-primary font-medium text-left">
                    Get more lead storage
                  </div>
                </div>
                <img src={ChevronRightBlue} className="h-6 w-6" alt="" />
              </button>
            </Spin>
          )}
        </div>
        <div className="p-6 flex flex-col gap-4">
          <Link
            to={`${AppRoutesObject.LeadCleanup.url}?orgId=${encryptedOrgId}`}
          >
            <button className="cursor-pointer w-full h-12 bg-primary text-white uppercase text-[15px] leading-5 font-medium rounded-full">
              clean up space
            </button>
          </Link>

          <button
            className="w-full h-12 border border-primary bg-white text-primary uppercase text-[15px] leading-5 font-medium rounded-full"
            onClick={onClose}
          >
            cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export const SomeLeadsNudge = (
  props: Readonly<{
    leadCount: number;
    maxLeads: number;
    onClose: () => void;
    orgId: number;
    planId: number;
  }>
) => {
  const { leadCount, maxLeads, onClose, orgId, planId } = props;
  const [loginOrg, { isFetching, isLoading }] = useLazyLoginOrgQuery();
  const dispatch = useDispatch();

  const encryptedOrgId = useMemo(() => {
    return encrypt(orgId);
  }, [orgId]);
  const isPro = planId === plan_ids["pro"];

  return (
    <div className="flex flex-col">
      <div className="h-[153px] bg-[#fdf3d8] flex justify-center items-center">
        <img
          src={WarningYellow}
          className="w-[115px] h-[115px]"
          alt="warning-yellow"
        />
      </div>
      <div className="flex flex-col">
        <div className="flex flex-col gap-2 px-6 py-4">
          <div className="text-[#cc9909] text-base">
            {maxLeads
              ? `${leadCount} of ${maxLeads} leads used`
              : `${leadCount} leads used`}
          </div>
          {maxLeads ? (
            <div className="text-[21px] leading-7 text-[#263238]">
              {`Only ${maxLeads - leadCount} more leads can be created.`}
            </div>
          ) : null}
          <div className="py-2 text-base text-[#61626b]">
            You can choose to import contacts without adding any tasks.
          </div>
        </div>
        <div className="px-6 py-4">
          {isPro ? (
            <button
              className="w-full h-12 border border-primary bg-white text-primary uppercase text-[15px] leading-5 font-medium rounded-full"
              onClick={() => {
                onClose();
                contactUs(`I want more leads in Pro plan`);
              }}
            >
              Contact Us
            </button>
          ) : (
            <Spin spinning={isFetching || isLoading}>
              <button
                onClick={async () => {
                  onClose();
                  await loginOrg(orgId);
                  dispatch(setUpgradeOrgId(orgId));
                }}
                className="w-full cursor-pointer p-3 flex gap-3 items-center bg-[#f0eefc] border border-[#d2c3f7] rounded-lg"
              >
                <img src={UserEngagement} className="w-10 h-10" alt="" />
                <div className="grow flex flex-col gap-1">
                  <div className="whitespace-nowrap flex gap-1 items-center">
                    <span className="text-[rgba(97, 98, 107, 1)] text-[13px] leading-5">
                      Upgrade to
                    </span>
                    <span className="flex gap-2 items-center">
                      <div className="uppercase text-[#9857bd] text-[13px] leading-5 font-semibold">
                        All-in-one{" "}
                      </div>
                      <img src={Pro} alt="pro" className="w-[52px] h-[22px]" />
                    </span>
                  </div>
                  <div className="text-base text-primary font-medium text-left">
                    Get more lead storage
                  </div>
                </div>
                <img src={ChevronRightBlue} className="w-6 h-6" alt="" />
              </button>
            </Spin>
          )}
        </div>
        <div className="p-6 flex flex-col gap-4">
          <Link
            to={`${AppRoutesObject.LeadCleanup.url}?orgId=${encryptedOrgId}`}
          >
            <button className="w-full cursor-pointer h-12 bg-primary text-white uppercase text-[15px] leading-5 font-medium rounded-full">
              clean up space
            </button>
          </Link>

          <button
            className="w-full h-12 border border-primary bg-white text-primary uppercase text-[15px] leading-5 font-medium rounded-full"
            onClick={onClose}
          >
            cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export const AssignmentLimitNudge = (
  props: Readonly<{ orgId: number; onClose?: () => void }>
) => {
  const { orgId, onClose } = props;
  const [loginOrg, { isFetching, isLoading }] = useLazyLoginOrgQuery();
  const dispatch = useDispatch();

  return (
    <div className="mt-4 px-6 py-4 flex flex-col gap-6">
      <div className="flex justify-center">
        <img src={Selection} className="w-20 h-20" alt="" />
      </div>
      <div className="flex flex-col gap-2 items-center justify-center">
        <div className="font-semibold text-base text-[#9857bd]">UPGRADE TO</div>
        <div className="text-[21px] leading-8 text-primary text-center">
          <span>{`Simplify CRM with `}</span>
          <span className="font-semibold">{`Agent Assignment`}</span>
        </div>
      </div>
      <div className="flex flex-col gap-3">
        <div className="flex gap-2 items-center">
          <img
            src={CheckYellow}
            className="w-[14px] h-[14px]"
            alt="yellow check"
          />
          <div className="text-[13px] leading-5 text-primary/75">
            Automatically assign and distribute leads
          </div>
        </div>
        <div className="flex gap-2 items-center">
          <img src={CheckPink} className="w-[14px] h-[14px]" alt="pink check" />
          <div className="text-[13px] leading-5 text-primary/75">
            Ring only assigned agents during incoming calls
          </div>
        </div>
        <div className="flex gap-2 items-center">
          <img
            src={CheckGreen}
            className="w-[14px] h-[14px]"
            alt="green check"
          />
          <div className="text-[13px] leading-5 text-primary/75">
            Track and manage tasks and performance
          </div>
        </div>
      </div>
      <Spin spinning={isFetching || isLoading}>
        <div
          onClick={async () => {
            onClose?.();
            await loginOrg(orgId);
            dispatch(setUpgradeOrgId(orgId));
          }}
          className="cursor-pointer w-full bg-[rgb(152,87,189)] p-3 rounded-lg flex gap-2 justify-between items-center"
        >
          <div className="flex flex-col gap-2">
            <div className="flex gap-2 items-center">
              <div className="uppercase text-white text-[13px] leading-5 font-medium">
                All-in-one{" "}
              </div>
              <img src={Pro} alt="pro" className="w-[52px] h-[22px]" />
            </div>
            <div className="text-white/75 text-[13px] leading-5">
              <span className="font-inter">₹</span>
              <span>999/month</span>
            </div>
          </div>
          <div className="flex gap-px items-center">
            <span className="text-white text-[13px] uppercase leading-5 font-medium">
              More Details
            </span>
            <img
              src={ChevronRightWhite}
              className="w-5 h-5"
              alt="chevron-right-white"
            />
          </div>
        </div>
      </Spin>
    </div>
  );
};

export const TagLimitNudge = (
  props: Readonly<{ orgId: number; onClose: () => void }>
) => {
  const { orgId, onClose } = props;
  const [loginOrg, { isFetching, isLoading }] = useLazyLoginOrgQuery();
  const dispatch = useDispatch();

  return (
    <div className="flex flex-col px-6 gap-4 py-4">
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          <div className="flex gap-2 items-center">
            <div className="text-[21px] leading-7 text-primary">Upgrade to</div>
            <img src={Pro} alt="pro" className="w-[52px] h-[22px]" />
          </div>
          <div className="text-[21px] leading-7 text-primary">
            for unlimited tags
          </div>
        </div>
        <div className="text-base text-[#61626b]">
          Easily organise all your contacts and leads without limits.
        </div>
      </div>
      <Spin spinning={isFetching || isLoading}>
        <div
          onClick={async () => {
            onClose();
            await loginOrg(orgId);
            dispatch(setUpgradeOrgId(orgId));
          }}
          className="cursor-pointer w-full bg-[#9857bd] p-3 rounded-lg flex gap-2 justify-between items-center"
        >
          <div className="flex flex-col gap-2">
            <div className="flex gap-2 items-center">
              <div className="uppercase text-white text-[13px] leading-5 font-medium">
                All-in-one{" "}
              </div>
              <img src={Pro} alt="pro" className="w-[52px] h-[22px]" />
            </div>
            <div className="text-white/75 text-[13px] leading-5">
              <span className="font-inter">₹</span>
              <span>999/month</span>
            </div>
          </div>
          <div className="flex gap-px items-center">
            <span className="text-white text-[13px] uppercase leading-5 font-medium">
              More Details
            </span>
            <img src={ChevronRightWhite} alt="right" className="w-5 h-5" />
          </div>
        </div>
      </Spin>
    </div>
  );
};

export const IntegrationLimitNudge = (
  props: Readonly<{ orgId: number; onClose: () => void }>
) => {
  const { orgId, onClose } = props;
  const [loginOrg, { isFetching, isLoading }] = useLazyLoginOrgQuery();
  const dispatch = useDispatch();

  return (
    <div className="flex flex-col px-6 gap-4 py-4">
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          <div className="flex gap-2 items-center">
            <div className="text-[21px] leading-7 text-primary">Upgrade to</div>
            <img src={Pro} alt="pro" className="w-[52px] h-[22px]" />
          </div>
          <div className="text-[21px] leading-7 text-primary">
            for unlimited lead source integrations
          </div>
        </div>
      </div>
      <Spin spinning={isFetching || isLoading}>
        <div
          onClick={async () => {
            onClose();
            await loginOrg(orgId);
            dispatch(setUpgradeOrgId(orgId));
          }}
          className="cursor-pointer w-full bg-[#9857bd] p-3 rounded-lg flex gap-2 justify-between items-center"
        >
          <div className="flex flex-col gap-2">
            <div className="flex gap-2 items-center">
              <div className="uppercase text-white text-[13px] leading-5 font-medium">
                All-in-one{" "}
              </div>
              <img src={Pro} alt="pro" className="w-[52px] h-[22px]" />
            </div>
            <div className="text-white/75 text-[13px] leading-5">
              <span className="font-inter">₹</span>
              <span>999/month</span>
            </div>
          </div>
          <div className="flex gap-px items-center">
            <span className="text-white text-[13px] uppercase leading-5 font-medium">
              More Details
            </span>
            <img src={ChevronRightWhite} alt="right" className="w-5 h-5" />
          </div>
        </div>
      </Spin>
    </div>
  );
};

export const LeadStageLimitNudge = (
  props: Readonly<{ orgId: number; onClose: () => void }>
) => {
  const { orgId, onClose } = props;
  const [loginOrg, { isFetching, isLoading }] = useLazyLoginOrgQuery();
  const dispatch = useDispatch();

  return (
    <div className="flex flex-col px-6 gap-4 py-4">
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          <div className="flex gap-2 items-center">
            <div className="text-[21px] leading-7 text-primary">Upgrade to</div>
            <img src={Pro} alt="pro" className="w-[52px] h-[22px]" />
          </div>
          <div className="text-[21px] leading-7 text-primary">
            for unlimited lead stages
          </div>
        </div>
        <div className="text-base text-[#61626b]">
          Easily organise all your contacts and leads without limits.
        </div>
      </div>
      <Spin spinning={isFetching || isLoading}>
        <div
          onClick={async () => {
            onClose();
            await loginOrg(orgId);
            dispatch(setUpgradeOrgId(orgId));
          }}
          className="cursor-pointer w-full bg-[#9857bd] p-3 rounded-lg flex gap-2 justify-between items-center"
        >
          <div className="flex flex-col gap-2">
            <div className="flex gap-2 items-center">
              <div className="uppercase text-white text-[13px] leading-5 font-medium">
                All-in-one{" "}
              </div>
              <img src={Pro} alt="pro" className="w-[52px] h-[22px]" />
            </div>
            <div className="text-white/75 text-[13px] leading-5">
              <span className="font-inter">₹</span>
              <span>999/month</span>
            </div>
          </div>
          <div className="flex gap-px items-center">
            <span className="text-white text-[13px] uppercase leading-5 font-medium">
              More Details
            </span>
            <img src={ChevronRightWhite} alt="right" className="w-5 h-5" />
          </div>
        </div>
      </Spin>
    </div>
  );
};

export const AutomationsNudge = (
  props: Readonly<{ orgId: number; onClose?: () => void }>
) => {
  const { orgId, onClose } = props;
  const summariesQuery = useGetSummariesQuery();
  const planID =
    summariesQuery?.data?.find((s) => s.org_id === orgId)?.subscription
      ?.plan_id || 0;
  const isPro = planID === plan_ids["pro"];
  const [loginOrg, { isFetching, isLoading }] = useLazyLoginOrgQuery();
  const dispatch = useDispatch();
  const viewPlans = async () => {
    onClose?.();
    await loginOrg(orgId);
    dispatch(setUpgradeOrgId(orgId));
  };
  return (
    <div className="p-4 flex flex-col gap-8">
      <div className="flex flex-col gap-2">
        <div className="flex gap-2 justify-between">
          <img src={WarningRed} className="w-10 h-10" alt="warning-red" />
          <img
            onClick={() => onClose?.()}
            src={Close}
            className="w-4 h-4 self-start cursor-pointer"
            alt="close"
          />
        </div>
        <div className="flex flex-col gap-2">
          <div className="text-[21px] leading-8 text-primary">
            You have reached your limit
          </div>
          <div className="text-base text-[#61626B]">{`Upgrade to ${
            isPro ? "Enterprise" : "Pro"
          } plan to create more automations`}</div>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4">
        <Button
          disabled={isFetching || isLoading}
          onClick={() => {
            contactUs("I need help with Automations");
          }}
          className="uppercase bg-white !rounded-full !h-12"
          icon={<CustomerServiceOutlined />}
        >
          Get help
        </Button>
        <Button
          onClick={viewPlans}
          disabled={isFetching || isLoading}
          loading={isFetching || isLoading}
          type="primary"
          className="uppercase !rounded-full !h-12"
        >
          view plans
        </Button>
      </div>
    </div>
  );
};
