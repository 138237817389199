import { Button, Modal } from "antd";
import type { ButtonProps } from "antd";
import {
  useCancelOrderMutation,
  useCreateOrgMutation,
  useGetOrgsQuery,
  useGetPendingOrderQuery,
  useGetSummariesQuery,
  useLazyLoginOrgQuery,
  useUsersSyncQuery,
} from "../../store/api/api";
import { contactUs } from "../../utils/utils";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { newTeamName } from "../../utils/constants";
import { useDispatch } from "react-redux";
import {
  setPurchaseOrgId,
  setRenewOrgId,
  setTopUpOrgId,
  setUpgradeOrgId,
} from "../../store/global/global.reducer";
import { useState } from "react";

export const PurchaseButton = (
  props: Readonly<
    ButtonProps & {
      textClassName?: string;
    }
  >
) => {
  const { textClassName, ...buttonProps } = props;
  const dispatch = useDispatch();
  const [createOrg] = useCreateOrgMutation();
  const [cancelOrder] = useCancelOrderMutation();
  const { refetch: refetchPendingOrder } = useGetPendingOrderQuery();
  const [loginOrg] = useLazyLoginOrgQuery();
  const { data: orgs, refetch: refetchOrgs } = useGetOrgsQuery();
  const { data: summaries, refetch: refetchSummaries } = useGetSummariesQuery();
  const { data: usersSync } = useUsersSyncQuery();
  const maxOrgCount = usersSync?.user?.org_creation_limit || 0;
  const maxReached = maxOrgCount === orgs?.length;
  const hasSingleOrg = orgs?.length === 1;
  const isTrial = summaries?.every((s) => s.is_trial);
  const [loading, setLoading] = useState(false);
  const createOrgAndOpenModal = async () => {
    const createdOrgResponse = await createOrg({
      name: newTeamName,
    });
    if ("data" in createdOrgResponse) {
      const orgId = createdOrgResponse?.data?.organization?.id || 0;
      await loginOrg(orgId);
      if (createdOrgResponse?.data?.organization?.id) {
        dispatch(setPurchaseOrgId(createdOrgResponse.data.organization.id));
      }
      Promise.all([refetchOrgs(), refetchSummaries()]);
    }
  };

  return (
    <Button
      {...buttonProps}
      loading={loading}
      disabled={loading || (hasSingleOrg && isTrial)}
      type="primary"
      onClick={async (e) => {
        e?.stopPropagation();
        e?.preventDefault();
        if (maxReached) {
          contactUs("I want to purchase additional superfone number");
        } else {
          setLoading(true);
          const pendingOrder = await refetchPendingOrder();
          const pendingOrderId = pendingOrder?.data?.id || 0;
          if (pendingOrderId) {
            const orgId = pendingOrder?.data?.org_id || 0;
            const orderType = pendingOrder?.data?.details?.order_type;
            await loginOrg(orgId);

            Modal.confirm({
              title: "You already have a payment in progress",
              icon: <ExclamationCircleOutlined />,
              content: "Cancel and start a new Payment?",
              cancelText: "Continue older payment",
              okText: "Start new",
              onOk: async () => {
                await cancelOrder({
                  order_id: pendingOrderId,
                  status: "cancelled",
                });
                await createOrgAndOpenModal();
              },
              cancelButtonProps: {
                onClick: () => {
                  if (orderType === "PURCHASE") {
                    dispatch(setPurchaseOrgId(orgId));
                  } else if (orderType === "TOPUP") {
                    dispatch(setTopUpOrgId(orgId));
                  } else if (orderType === "RENEW") {
                    dispatch(setRenewOrgId(orgId));
                  } else if (orderType === "UPGRADE") {
                    dispatch(setUpgradeOrgId(orgId));
                  }
                  Modal.destroyAll();
                },
              },
              closable: true,
            });
          } else {
            await createOrgAndOpenModal();
          }
          setLoading(false);
        }
      }}
    >
      <span
        className={`${
          textClassName || ""
        } max-w-full text-ellipsis whitespace-nowrap overflow-hidden`}
      >
        {maxReached ? "Contact Us to Buy a New Number" : "Buy a new Number !"}
      </span>
    </Button>
  );
};
