//
// const accountId = process.env.REACT_APP_CLEVERTAP_ACCOUNT_ID || "";
//
// // Set the flag to true, if the user of the device opts out of sharing their data
// clevertap.privacy.push({ optOut: false });
// // Set the flag to true, if the user agrees to share their IP data
// clevertap.privacy.push({ useIP: false });
// // Replace with values applicable to you. Refer below
// clevertap.init(accountId, "in1");
// //set to true if you use Single Page Application
// clevertap.spa = true;

export enum EVENT_NAME {
  PAGE_VIEW = "PAGE_VIEW",
}

export enum PAGE_SIZE {
  BOTTOMSHEET = "Bottomsheet",
  FULL_PAGE = "Full Page",
}

export enum PAGE_NAME {
  HOME_PAGE = "OD Landing Page",
  NUMBERS = "Numbers",
  TEAMS = "Teams",
  STAFF = "Staffs",
  CONTACTS = "Contacts",
  CONTACT_PAGE = "Contact Page",
  CALL_HISTORY = "Call History",
  INSIGHTS = "Insights",
  CONTACT_US = "Contact Us",
  CRM_INSIGHTS = "CRM Insights",
  NOT_FOUND = "Not Found",
  NOTES = "Notes",
  SETTINGS = "Settings",
  CHAT = "Chat",
  STAFF_PAGE = "Staff Page",
  TASKS = "Tasks",
  TEAM_PAGE = "Org Page",
  WHATSAPP_PAGE = "Whatsapp Page",
  INTEGRATIONS = "Integrations",
}

type ClevertapEvent = {
  event_name: EVENT_NAME.PAGE_VIEW;
  event_data: {
    PageName: PAGE_NAME;
    PageSize: PAGE_SIZE;
  };
};

export const clevertapPush = (event: ClevertapEvent) => {
  // const { event_name, event_data } = event;
  // const state = store.getState();
  // const userSync = api.endpoints.usersSync.select()(state).data;
  // const userName = `${userSync?.user?.first_name || ""} ${
  //   userSync?.user?.last_name || ""
  // }`.trim();
  // const roleName = userSync?.user?.role_name || "";
  // const userOrg = userSync?.org;
  // const orgStatus = getOrgStatus(userSync?.current_subscription);
  // const planType = getPlanType(userSync?.current_subscription?.subscription);
  // const orgKYC = userSync?.kyc?.status || "";
  // const org_created_at = userSync?.org?.created_at || "";
  // const user = {
  //   "User Name": userName,
  //   "User Role": roleName,
  //   "Org ID": userOrg?.id || 0,
  //   "Org Name": userOrg?.name || "",
  //   "Org Status": orgStatus,
  //   "Subscription Plan Type": planType,
  //   // "Count of Team Members": 3,
  //   // "OD User": "",
  //   "Org KYC Status": orgKYC,
  //   "Org creation date": org_created_at
  //     ? dayjs(org_created_at).format(DISPLAY_DATE_FORMAT)
  //     : "",
  // };
  // const obj = {
  //   ...user,
  //   ...event_data,
  // };
  // clevertap.event.push(event_name, obj);
};

export enum ORG_STATUS {
  TRIAL_ACTIVE = "TRIAL_ACTIVE",
  PAID_ACTIVE = "PAID_ACTIVE",
  TRIAL_EXPIRED = "TRIAL_EXPIRED",
  PAID_EXPIRED = "PAID_EXPIRED",
  ALL = "ALL",
  PAID_ABOUT_TO_EXPIRE = "PAID_ABOUT_TO_EXPIRE",
  REFUNDED = "REFUNDED",
  DEMO_MODE = "DEMO_MODE",
  PAID_ABOUT_TO_EXPIRE_VERY_SOON = "PAID_ABOUT_TO_EXPIRE_VERY_SOON",
}

//
// const getOrgStatus = (current_subscription?: CurrentSubscription) => {
//   const {
//     is_active,
//     is_expiring_soon,
//     is_paid_org,
//     is_refunded,
//     is_trial,
//     is_trial_active,
//   } = current_subscription || {};
//   //paid active
//   if (is_paid_org && is_expiring_soon) {
//     return ORG_STATUS.PAID_ABOUT_TO_EXPIRE;
//   }
//
//   if (is_paid_org && is_active) {
//     return ORG_STATUS.PAID_ACTIVE;
//   }
//
//   //paid inactive
//   if (is_paid_org && !is_active) {
//     return ORG_STATUS.PAID_EXPIRED;
//   }
//
//   //trial
//   if (is_trial && is_trial_active) {
//     return ORG_STATUS.TRIAL_ACTIVE;
//   }
//   if (is_trial && !is_trial_active) {
//     return ORG_STATUS.TRIAL_EXPIRED;
//   }
//
//   //refunded
//   if (is_refunded) {
//     return ORG_STATUS.REFUNDED;
//   }
//
//   //demo
//   if (!is_active && !is_paid_org && !is_trial && !is_trial_active) {
//     return ORG_STATUS.DEMO_MODE;
//   }
//   return ORG_STATUS.ALL;
// };

export enum SUBSCRIPTION_PLAN_TYPE {
  Trial = "Trial",
  Standard = "Standard",
  Premium = "Premium",
  Custom = "Custom",
}

// const getPlanType = (subscription?: CurrentSubscription["subscription"]) => {
//   const title = (subscription?.title || "").toLowerCase();
//   if (title.includes("standard")) {
//     return SUBSCRIPTION_PLAN_TYPE.Standard;
//   }
//   if (title.includes("premium")) {
//     return SUBSCRIPTION_PLAN_TYPE.Premium;
//   }
//   if (title.includes("custom")) {
//     return SUBSCRIPTION_PLAN_TYPE.Custom;
//   }
//   return SUBSCRIPTION_PLAN_TYPE.Trial;
// };
