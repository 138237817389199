import { Modal, ModalProps } from "antd";
import { PropsWithChildren } from "react";

export const BasicModal = (props: PropsWithChildren<ModalProps>) => {
  const { children, styles, ...rest } = props;
  return (
    <Modal
      closeIcon={null}
      title={null}
      footer={null}
      centered
      styles={{
        ...styles,
        content: {
          ...styles?.content,
          padding: 0,
          overflow: "hidden",
        },
      }}
      {...rest}
    >
      {children}
    </Modal>
  );
};
