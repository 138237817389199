export const phoneNumberRules = [
  { max: 10, message: "Enter 10 digit phone number" },
  {
    required: true,
    whitespace: true,
    message: "Please enter phone Number",
  },
  {
    pattern: new RegExp("^[0-9+].{9}"),
    message: "Phone number must be 10 digits",
  },
  {
    pattern: new RegExp("^[1-9][0-9+]"),
    message: "0 is not required as country code is already applied.",
  },
  {
    pattern: new RegExp("^[04-9][0-9+]"),
    message: "Invalid phone number",
  },
];
