import {
  ErrorWrapper,
  ImageWrapper,
  ContentWrapper,
  TitleWrapper,
  StyledTitle,
  MessageWrapper,
  StyledMessage,
  ActionWrapper,
} from "./error-screen.styles";
import ErrorImg from "../../assets/images/Error.png";
import { Button } from "antd";

export type ErrorScreenProps = Readonly<{
  errorTitle?: string;
  errorMessage?: string;
  onAction?:
    | (React.MouseEventHandler<HTMLAnchorElement> &
        React.MouseEventHandler<HTMLButtonElement>)
    | undefined;
}>;

export const ErrorScreen = (props: ErrorScreenProps) => {
  const { errorTitle = "", errorMessage = "", onAction } = props;

  return (
    <ErrorWrapper>
      <ImageWrapper>
        <img src={ErrorImg} alt="Error Img" />
      </ImageWrapper>
      <ContentWrapper>
        <TitleWrapper>
          <StyledTitle>{errorTitle || "Uh Oh!"}</StyledTitle>
        </TitleWrapper>
        <MessageWrapper>
          <StyledMessage>{errorMessage || ""}</StyledMessage>
        </MessageWrapper>
      </ContentWrapper>
      {onAction ? (
        <ActionWrapper>
          <Button type="primary" onClick={onAction}>
            GO HOME
          </Button>
        </ActionWrapper>
      ) : null}
    </ErrorWrapper>
  );
};
