import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  SendMessagePayload,
  SendMessageResponse,
} from "./types/send-message.types";
import { WhatsappContact } from "./types/get-whatsapp-contacts.types";
import { WhatsappMessage } from "./types/get-whatsapp-messages.types";
import {
  PresignURLPayload,
  PresignURLResponse,
} from "./types/presign-url.types";
import { WhatsappTemplate } from "./types/get-whatsapp-templates.types";
import { CreateWhatsappTemplatePayload } from "./types/create-whatsapp-template.types";
import { UpdateWhatsappTemplatePayload } from "./types/update-whatsapp-template.types";
import { GenericAPIResponse } from "./api";

export const whatsappApi = createApi({
  reducerPath: "whatsappApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_HOST,
    credentials: "include",
  }),
  endpoints: (builder) => ({
    sendMessage: builder.mutation<SendMessageResponse, SendMessagePayload>({
      query: (body) => ({
        url: `/dragonfly/whatsapp/send`,
        method: "post",
        body,
      }),
      transformResponse: (response: GenericAPIResponse<SendMessageResponse>) =>
        response.data,
    }),
    getWhatsappContacts: builder.query<WhatsappContact[], void>({
      query: () => ({
        url: "/dragonfly/whatsapp/contacts",
      }),
      transformResponse: (response: GenericAPIResponse<WhatsappContact[]>) =>
        response.data,
    }),
    getContactMessages: builder.query<WhatsappMessage[], string>({
      query: (id) => ({
        url: `/dragonfly/whatsapp/${id}/messages`,
      }),
      transformResponse: (response: GenericAPIResponse<WhatsappMessage[]>) =>
        response.data,
    }),
    presignURL: builder.mutation<PresignURLResponse, PresignURLPayload>({
      query: (payload) => ({
        url: `/s3/presignURL?file_name=${payload.file_name}&file_type=${payload.file_type}&public=true`,
        method: "get",
      }),
      transformResponse: (response: GenericAPIResponse<PresignURLResponse>) =>
        response.data,
    }),
    getWhatsappTemplates: builder.query<WhatsappTemplate[], string>({
      query: (token) => ({
        url: `https://whatsapp-api.superfone.co.in/v1/templates`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
      transformResponse: (response: GenericAPIResponse<WhatsappTemplate[]>) =>
        response.data,
    }),
    createWhatsappTemplate: builder.mutation<
      any,
      CreateWhatsappTemplatePayload
    >({
      query: (body) => ({
        url: `/dragonfly/whatsapp/message_templates`,
        method: "post",
        body,
      }),
      transformResponse: (response: GenericAPIResponse<WhatsappTemplate>) =>
        response.data,
    }),
    updateWhatsappTemplate: builder.mutation<
      any,
      UpdateWhatsappTemplatePayload
    >({
      query: (payload) => {
        const { id, ...body } = payload;
        return {
          url: `/dragonfly/whatsapp/message_templates/${id}`,
          method: "post",
          body,
        };
      },
      transformResponse: (response: GenericAPIResponse<WhatsappTemplate>) =>
        response.data,
    }),
  }),
});

export const {
  useGetWhatsappContactsQuery,
  useSendMessageMutation,
  useGetContactMessagesQuery,
  usePresignURLMutation,
  useGetWhatsappTemplatesQuery,
  useCreateWhatsappTemplateMutation,
  useUpdateWhatsappTemplateMutation,
} = whatsappApi;
