import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import {
  createRoutesFromChildren,
  HashRouter,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { ConfigProvider } from "antd";
import { store } from "./store/store";
import React from "react";
import * as Sentry from "@sentry/react";
import { Facebook } from "./store/api/types/fb.types";

const basename = process.env.REACT_APP_BASE_NAME!;
if (process.env.REACT_APP_MODE === "production") {
  Sentry.init({
    dsn: "https://79bce5a6405999d560a6c350622031f1@o1133082.ingest.sentry.io/4506705095098368",
    integrations: [
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 0.01,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ["localhost", /^https:\/\/superfone\.in\/api/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.01,
    replaysOnErrorSampleRate: 1.0,
  });
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <HashRouter {...(basename ? { basename } : {})}>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: "#1E48F7",
            },
          }}
        >
          <App />
        </ConfigProvider>
      </HashRouter>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();

declare global {
  interface Window {
    referredCallerTune: boolean;
    FB: Facebook;
    Razorpay: any;
  }
}
