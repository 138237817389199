import styled from "styled-components";
import { colors } from "../../utils/constants";

export const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 8px 24px;
  gap: 10px;
`;

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 8px 0px;
  gap: 8px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export const StyledTitle = styled.div`
  font-size: 21px;
  line-height: 28px;
  text-align: center;
  color: ${colors.primary};
`;

export const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  gap: 16px;
`;

export const StyledMessage = styled.div`
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: ${colors.greyDark1};
`;

export const ActionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 0px;
  gap: 8px;
`;
